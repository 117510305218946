export default {
  MENU_TITLE: 'Меню мережі',
  ADD: 'Додати меню',
  ADD_NEW: 'Додавання меню',
  BACK_BTN: 'Повернутися',
  ADD_TITLE: 'Додайте своє перше меню',
  ADD_SUBTITLE:
    'Додайте меню, яке надалі можна активувати в одному або кількох закладах вашої мережі, після чого воно стане доступним персоналу та відвідувачам.',
  TITLE: 'Назва',
  COUNT: 'Позицій',
  ACTIVE: 'Активне',
  NETWORK: 'Мережа',
  MENU_PLACEHOLDER: 'Введіть назву меню',
  MENU_LABEL: 'Назва',
  BACK_MENU: 'До списку меню',
  TITLE_SUCCESS: 'Меню додано!',
  SUBTITLE_SUCCESS:
    'Тепер можна перейти до налаштувань меню: додати категорії, страви та напої, вказати ціни й вагу. Або додайте ще одне меню.',
  BACK_SETTINGS: 'До налаштувань',
  ADD_MORE: 'Додати інше меню',
  WAS: 'було',
  ACTIVATED: 'активовано',
  DEACTIVATED: 'деактивовано',
  ADD_SECTION: 'Додати розділ',
  ADD_NEW_SECTION: 'Додавання',
  ADD_NEW_PRICE_TYPE: 'Додавання типів цін',
  ADD_PRICE_TYPE: 'Додати тип',
  EDIT: 'Редагування',
  PLUG_TITLE: 'Додайте розділи меню',
  PLUG_TITLE_PRICE_TYPE: 'Додати типи цін',
  PLUG_SUBTITLE:
    'Створіть розділи та згрупуйте позиції, які входять до меню, щоб персоналу і відвідувачам було зручніше знайти необхідний товар.',
  PLUG_SUBTITLE_PRICE_TYPE:
    'Використовуйте типи цін для розширення можливостей ціноутворення. Це дозволить запропонувати гнучкіші цінові умови відвідувачам вашої мережі',
  NAME: 'Назва',
  MENU: 'Меню',
  SEARCH: 'Пошук',
  PH_NAME_PRICE_TYPE: 'Наприклад "Для персоналу"',
  GOODS: 'Товар',
  CALC: 'Метод розрахунку ціни',
  PRICE_VALUE: 'Значення',
  SELECT_TYPE: 'Оберіть вид надбавки',
  FOR_ALL_TEXT: 'Для всіх товарів',
  UPDATED: 'меню було успішно оновлено',
  DISCOUNT: 'Максимальна знижка, %',
  PARENT_CATEGORY: 'Батьківська категорія',
  ICON_CATEGORY: 'Іконка категорії',
  SELECT_ICON: 'Оберіть іконку',
  DISCOUNT_ERROR: 'Значення має бути у диапазоні 0 - 100',
  REMOVE: 'меню було успішно видалено',
  REMOVE_PRICE_TYPE: 'тип був успішно видален',
  WAS_SUCCESS: 'розділ меню був успішно',
  WAS_SUCCESS_PRICE_TYPE: 'тип був успішно',
  CREATE: 'додан',
  UPDATE: 'оновлен',
  DELETE_TOOLTIP: 'Цей розділ не може бути видалений, тому що існують підпорядковані йому інші розділи.',
  PASSIVE_TOOLTIP: 'Цей розділ не може бути змінений, тому що вимкнений управлінський розділ.',
  SWITCH_TOOLTIP:
    'Цей розділ не може бути змінений, тому що вимкнений управлінський розділ або існують підпорядковані йому активні інші розділи.',
  FOR_ALL: 'Всі',
  SELECTED: 'Вибрані',
  SELECT_ALL: 'Добавити всі',
  FIXED: 'Фіксована ціна',
  BASIC: 'Від основної ціни',
  COST: 'Від собівартості',
  MARKUP: 'Від % націнки',
  UPDATED_TYPE: 'тип було успішно оновлено',
  VALUE_ERR: 'Значення має бути ціфрою',
  NOTIFY_ACTIVE: 'Включити активність у всіх підпорядкованих розділів?',
  NOTIFY_DEACTIVE:
    'При вимиканні активності батьківського розділу автоматично стануть неактивними всі підпорядковані йому розділи! Продовжити?',
};
