import React, {useRef, useState, useEffect, ReactNode, FC} from 'react';
import styled from 'styled-components';
import Tooltip from '@mui/material/Tooltip';

interface Props {
  children: ReactNode | string;
}

const BaseTruncate: FC<Props> = ({children, ...other}) => {
  const [isOverflowed, setIsOverflow] = useState<boolean>(false);
  const textElementRef = useRef<any>();

  useEffect(() => {
    const {
      current: {scrollWidth, clientWidth},
    } = textElementRef;
    setIsOverflow(scrollWidth > clientWidth);
  }, []);

  return (
    <Tooltip title={children} disableHoverListener={!isOverflowed} arrow {...other}>
      <Content ref={textElementRef}>{children}</Content>
    </Tooltip>
  );
};

const Content = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default BaseTruncate;
