import en from './localization/EN';
import uk from './localization/UK';
import {auth, masksParams, LocalizationObjProps, place, user, UserInfoProps} from 'services/GlobalUtils';

const localizationObj = {en, uk} as LocalizationObjProps;
export const localization = (language: string) => localizationObj[language];

const {anyTypeMask} = masksParams;

export const authInitialState: UserInfoProps = {
  telephone: '',
  telephoneMask: anyTypeMask,
  isTelephoneWrong: false,
  password: '',
  isPasswordWrong: false,
  isSendDisable: true,
  isError: false,
};

export const authUser = (phone: string, password: string) => auth.post('/auth/token/manager', {phone, password});

// Get user info
export const getUserProfile = (token?: string) =>
  user
    .get('/managers/profile', {headers: {Authorization: token || localStorage.getItem('BearerToken')}})
    .then(({data}) => data);

// User logout, killing back end token
export const userLogout = () => user.post('/auth/token/logout');

// Get network info
export const getNetworkInfo = (networkId: string) => place.get(`/networks/${networkId}`).then(({data}) => data);
