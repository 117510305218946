import React, {FC, useEffect, useMemo, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import BaseSeparatorLine from 'components/BaseComponents/BaseSeparatorLine';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import Header from 'components/GeneralComponents/Header';
import {useAppDispatch, useAppSelector} from 'services/hooks';
import {selectUserConfiguration} from 'store/userSlice';
import {initializeNetwork} from 'store/middleware/userThunk';
import {globalLocalization} from 'services/GlobalUtils';
import {ReactComponent as Establishment} from 'assets/icons/places.svg';
import {ReactComponent as Menu} from 'assets/icons/side_menu.svg';
import {ReactComponent as Organizations} from 'assets/icons/organizations.svg';
import {ReactComponent as Managers} from 'assets/icons/managers.svg';
import {ReactComponent as Profile} from 'assets/icons/user.svg';
import {font_title_large} from 'theme/fonts';

const PageLayout: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {pathname} = useLocation();
  const {language, isOwner} = useAppSelector(selectUserConfiguration);
  const {ESTABL_TITLE, ORGS_TITLE, MENU, PROFILE_TITLE, MANAGERS} = globalLocalization(language);
  const [isLoading, setLoading] = useState<boolean>(true);

  const sidebarHeader = useMemo(
    () => [
      {
        to: '/places',
        icon: <Establishment />,
        text: ESTABL_TITLE,
      },
      {
        to: '/menus',
        icon: <Menu />,
        text: MENU,
      },
      {
        to: '/organizations',
        icon: <Organizations />,
        text: ORGS_TITLE,
      },
      {
        to: '/managers',
        icon: <Managers />,
        text: MANAGERS,
      },
      {
        to: '/profile',
        icon: <Profile />,
        text: PROFILE_TITLE,
      },
    ],
    [ESTABL_TITLE, MANAGERS, MENU, ORGS_TITLE, PROFILE_TITLE]
  );

  useEffect(() => {
    dispatch(initializeNetwork());
  }, [dispatch]);

  useEffect(() => {
    !localStorage.getItem('BearerToken') && navigate('/auth');
    setLoading(false);
    window.scrollTo({top: 0, left: 0});
  }, [navigate, isOwner]);

  const element = useMemo(() => sidebarHeader.find(({to}) => pathname === to), [pathname, sidebarHeader]);

  return isLoading ? (
    <BaseLoader />
  ) : (
    <Wrapper>
      <Header />
      <Main>
        {element && (
          <>
            <Sidebar>
              <SidebarContent>
                <Icon icon={element.icon} />
                <PageTitle>{element.text}</PageTitle>
              </SidebarContent>
            </Sidebar>
            <SeparatorLine />
          </>
        )}
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Main>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: ${({theme}) => theme.colors.gray100};
  background-color: ${({theme}) => theme.colors.gray010};
  padding-bottom: 1.5rem;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    padding-bottom: 2rem;
  }
`;

const Main = styled.div`
  display: block;
  min-height: calc(100vh - 6.5rem);
  margin: 0;
  border-radius: 1.5rem;
  background-color: ${({theme}) => theme.colors.gray000};

  @media ${({theme}) => theme.breakpoints.minTb} {
    margin: 0 1.5rem;
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: grid;
    grid-template-columns: 15.625rem 1px auto;
    min-height: calc(100vh - 6.5rem);
  }
`;

const Container = styled.div`
  max-width: 100%;
  padding: 1.25rem 1rem 3.75rem;
  box-sizing: border-box;
  overflow: hidden;

  @media ${({theme}) => theme.breakpoints.minTb} {
    padding: 2rem 1.5rem 3.75rem;
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    padding: 3rem 1.5rem 3.75rem;
    margin: 0.75rem 0 1.25rem;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    width: clamp(677px, 94%, 1440px);
  }
`;

const Sidebar = styled.div`
  display: none;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    ${font_title_large};
    display: block;
    padding: 3rem 2rem 5rem;
    text-align: center;
  }
`;

const SidebarContent = styled.div`
  position: sticky;
  top: 7.5rem;

  @media print {
    display: none;
  }
`;

const PageTitle = styled.div`
  max-width: 140px;
  margin: 0 auto;
`;

const SeparatorLine = styled(BaseSeparatorLine)`
  display: none;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: block;
    height: 100%;
    width: 1px;
    background-color: ${({theme}) => theme.colors.gray020};
    border: 0;
  }
`;

const Icon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    fill: ${({theme}) => theme.colors.green060};
    width: 3.75rem;
    height: 3.75rem;
    margin-bottom: 0.75rem;
  }
`;

export default PageLayout;
