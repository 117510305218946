import React, {FC, ReactNode} from 'react';
import styled from 'styled-components';
import BaseTruncate from './BaseTruncate';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

interface Props {
  onChange?: (e: any) => void;
  selectValue?: string | number;
  disabled?: boolean;
  label?: string;
  menuClassName?: string;
  options: OptionsProps[];
}

interface OptionsProps {
  value: string | number;
  label: string | number | ReactNode;
  img?: ReactNode;
}

const BaseSelector: FC<Props> = ({
  options,
  label,
  disabled,
  menuClassName,
  onChange,
  selectValue = options[0]?.value,
  ...other
}) => (
  <FormControl {...other}>
    {label && <InputLabel>{label}</InputLabel>}
    <Select
      onChange={onChange}
      value={selectValue}
      disabled={disabled}
      label={label}
      MenuProps={{className: menuClassName}}
    >
      {options.map(({value, label, img}) => (
        <MenuItem key={value} value={value}>
          {img && <Image>{img}</Image>}
          <BaseTruncate>{label}</BaseTruncate>
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);

const Image = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
`;

export default BaseSelector;
