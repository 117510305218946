import React, {FC} from 'react';

interface Props {
  src: string | undefined;
  alt?: string;
}

const BaseImage: FC<Props> = ({src, alt, ...other}) => <img src={src} alt={alt} {...other} />;

export default BaseImage;
