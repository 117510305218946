import {place, LocalizationObjProps, masksParams, user} from 'services/GlobalUtils';
import en from './localization/EN';
import uk from './localization/UK';
import {ManagerStateProps} from '../Managers/ManagersUtils';

const {anyTypeMask} = masksParams;

export interface CategoriesProps {
  createdAt: string;
  id: string | number;
  isActive: boolean;
  orderNum: number;
  translations: {
    [key: string]: {
      id: string | number;
      locale: string;
      name: string;
    };
  };
}

export interface SocialsProps {
  id: string;
  link: string;
  isActive: boolean;
  orderNum: number;
  createdAt: string;
}

export interface SettingsProps {
  startTime: string | null;
  finishTime: string | null;
  daysBeforeOrder: number;
  isShiftOpen: boolean;
  isShiftClose: boolean;
  isPriceCategories: boolean;
  isCalories: boolean;
  isTakeout: boolean;
  isDelivery: boolean;
  isBrakeBill: boolean;
  isIdentifyPhone: boolean;
  isPreOrder: boolean;
  isStatusOrder: boolean;
  isContinueOrder: boolean;
  isSettle: boolean;
  isWaiterInfo: boolean;
  isTipping: boolean;
}

export interface PhotosProps {
  id: string;
  path: string;
  name: string;
  size: string;
  mimeType: string;
  isActive: boolean;
  createdAt: string;
}

export interface YouTubesProps {
  id: string;
  link: string;
  name: string;
  code: string;
  metadata: string[];
  isActive: boolean;
  createdAt: string;
}

export interface EstablishmentProps {
  id: string;
  site: string;
  email: string;
  isActive: boolean;
  logo: string;
  menuImage: string;
  phone: string;
  categories?: CategoriesProps[];
  placeHasSocials?: SocialsProps[];
  settings?: SettingsProps[];
  placeHasPhotos?: PhotosProps[];
  placeHasYouTubes?: YouTubesProps[];
  coordinates: {
    latitude: number;
    longitude: number;
  };
  translations: {
    [key: string]: {
      id: string | number;
      locale: string;
      address: string;
      description?: string;
      name: string;
    };
  };
}

export interface PlaceListProps {
  isLoading: boolean;
  isAddMode: boolean;
  tableType: string;
  page: number;
  pageCount: number;
  total: number;
  rowsPerPage: number;
  orderByName: 'asc' | 'desc';
  establishments: EstablishmentProps[];
}

export interface CategoryOption {
  id: string | number;
  value: string;
}

export interface PlaceSettingStateType {
  place: EstablishmentProps;
  tabNumber: number;
  isLoading: boolean;
}

export interface PlaceDetailProps {
  id: string | number;
  translationIdUK: string;
  translationIdEN: string;
  nameUK: string;
  nameEN: string;
  descriptionUK: string;
  descriptionEN: string;
  email: string;
  site: string;
  address: string;
  latitude: string | number;
  longitude: string | number;
  selectedCategories: CategoryOption[];
  categoriesOptions: CategoryOption[];
  telephoneMask: string;
  phone: string;
  policy: boolean;
  isSuccess: boolean;
  logo?: string;
  categoriesValue?: CategoryOption[];
  availableManagers: string[];
}

export interface PlaceValidationType {
  isTelephoneWrong: boolean;
  isEmailWrong: boolean;
  isWebsiteWrong: boolean;
  errorMessage: string;
  isError: boolean;
  isSendDisable: boolean;
}

export interface ComponentStateType {
  isLoading: boolean;
  isSendDisable: boolean;
}

const localizationObj = {en, uk} as LocalizationObjProps;
export const localization = (language: string) => localizationObj[language];

// Get list of places
export const getPlaces = (networkId: string, page: number, itemsPerPage: number, orderByName: string, locale: string) =>
  place
    .get(
      `/places/${networkId}/list?page=${page}&itemsPerPage=${itemsPerPage}&order%5Btranslations.name%5D=${orderByName}&order_locale=${locale}`
    )
    .then((data) => data);

// Create new place
export const createPlace = (
  network: string,
  site: string,
  phone: string,
  email: string,
  nameUK: string,
  nameEN: string,
  descriptionUK: string,
  descriptionEN: string,
  address: string,
  categories: CategoryOption[],
  latitude: number,
  longitude: number
) =>
  place
    .post('/places', {
      network,
      site,
      phone: phone.replace(/[\s-]/g, ''),
      email,
      categories: categories.map(({id}) => id),
      coordinates: {
        latitude,
        longitude,
      },
      translations: {
        uk: {
          name: nameUK,
          address,
          description: descriptionUK,
          locale: 'uk',
        },
        en: {
          name: nameEN || nameUK,
          address,
          description: descriptionEN || descriptionUK,
          locale: 'en',
        },
      },
    })
    .then((data) => data);

// Get list of categories
export const getCategories = (page = 1, itemsPerPage = 100, orderByName = 'asc') =>
  place
    .get(`/share/categories/list?page=${page}&itemsPerPage=${itemsPerPage}&order%5Btranslations.name%5D=${orderByName}`)
    .then(({data: {data}}) => data);

// Attaching super managers for place
export const updateManagerPlaces = (managers: ManagerStateProps[], place: string) =>
  user
    .post('/manager/places-multiple', {
      items: managers.map(({id}) => ({manager: `managers/${id}`, place})),
    })
    .then(({data: {data}}) => data);

export const PlacesListInitialState: PlaceListProps = {
  isLoading: true,
  isAddMode: false,
  tableType: 'list',
  page: 1,
  pageCount: 1,
  total: 0,
  rowsPerPage: 25,
  orderByName: 'asc',
  establishments: [],
};

export const EstablishmentInitialState: EstablishmentProps = {
  id: '',
  site: '',
  email: '',
  isActive: true,
  logo: '',
  menuImage: '',
  phone: '',
  coordinates: {
    latitude: 0,
    longitude: 0,
  },
  categories: [],
  translations: {
    uk: {
      id: 0,
      locale: 'uk',
      address: '',
      description: '',
      name: '',
    },
    en: {
      id: 1,
      locale: 'en',
      address: '',
      description: '',
      name: '',
    },
  },
};

export const InitialPlaceSettingState: PlaceSettingStateType = {
  place: EstablishmentInitialState,
  tabNumber: 0,
  isLoading: true,
};

export const initPlaceSettingsComponentState: ComponentStateType = {
  isLoading: true,
  isSendDisable: false,
};

export const placeDetailInitialState: PlaceDetailProps = {
  id: '',
  translationIdUK: '',
  translationIdEN: '',
  nameUK: '',
  nameEN: '',
  descriptionUK: '',
  descriptionEN: '',
  email: '',
  site: '',
  address: '',
  latitude: 0,
  longitude: 0,
  selectedCategories: [],
  categoriesOptions: [],
  telephoneMask: anyTypeMask,
  phone: '',
  policy: false,
  isSuccess: false,
  logo: '',
  categoriesValue: [],
  availableManagers: [],
};

export const placeValidationInitState: PlaceValidationType = {
  isTelephoneWrong: false,
  isEmailWrong: false,
  isWebsiteWrong: false,
  errorMessage: '',
  isSendDisable: false,
  isError: false,
};

export const settingsInitialState: SettingsProps = {
  startTime: null,
  finishTime: null,
  daysBeforeOrder: 0,
  isShiftOpen: false,
  isShiftClose: false,
  isPriceCategories: false,
  isCalories: false,
  isTakeout: false,
  isDelivery: false,
  isBrakeBill: false,
  isIdentifyPhone: false,
  isPreOrder: false,
  isStatusOrder: false,
  isContinueOrder: false,
  isSettle: false,
  isWaiterInfo: false,
  isTipping: false,
};

export const kievCoordinates = {
  lat: 50.45,
  lng: 30.52,
};
