import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'services/reduxStore';

export interface MenuState {
  selectedPositionId: string | number;
  selectedMenuId: string;
  selectedMenuName: string;
}

const initialState: MenuState = {
  selectedMenuId: localStorage.getItem('menuId') || '',
  selectedPositionId: localStorage.getItem('positionId') || '',
  selectedMenuName: localStorage.getItem('menuName') || '',
};

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenuId: (state, action: PayloadAction<string>) => {
      state.selectedMenuId = action.payload;
      localStorage.setItem('menuId', action.payload);
    },
    setPositionId: (state, action: PayloadAction<string>) => {
      state.selectedPositionId = action.payload;
      localStorage.setItem('positionId', action.payload);
    },
    setMenuName: (state, action: PayloadAction<string>) => {
      state.selectedMenuName = action.payload;
      localStorage.setItem('menuName', action.payload);
    },
  },
});

export const selectMenusConfiguration = (state: RootState) => state.menus;

export const {setMenuId, setPositionId, setMenuName} = menusSlice.actions;

export default menusSlice.reducer;
