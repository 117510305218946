import React, {FC} from 'react';
import styled from 'styled-components';
import BaseButton from 'components/BaseComponents/BaseButton';
import {useAppSelector} from 'services/hooks';
import {selectUserConfiguration} from 'store/userSlice';
import {globalLocalization} from 'services/GlobalUtils';

interface ErrorMessageProps {
  message: string;
}

interface ErrorBoundaryProps {
  error: ErrorMessageProps;
  resetErrorBoundary: () => void;
}

const ErrorFallback: FC<ErrorBoundaryProps> = ({error, resetErrorBoundary}) => {
  const {language} = useAppSelector(selectUserConfiguration);
  const {ERROR_TITLE, BUTTON_TEXT} = globalLocalization(language);

  return (
    <>
      <Text>{ERROR_TITLE}</Text>
      <ErrorText>{error.message}</ErrorText>
      <Button onClick={resetErrorBoundary}>{BUTTON_TEXT}</Button>
    </>
  );
};

const Text = styled.div`
  margin: 0.625rem 0;
  text-align: center;
`;

const ErrorText = styled(Text)`
  margin: 0;
`;

const Button = styled(BaseButton)`
  &.MuiButton-root {
    display: flex;
    margin: 0.625rem auto auto;
    padding: 0.5rem 2.25rem;
    background-color: ${({theme}) => theme.colors.green060};
    color: ${({theme}) => theme.colors.gray000};

    &:hover {
      background-color: ${({theme}) => theme.colors.green060};
      color: ${({theme}) => theme.colors.gray000};
    }
  }
`;

export default ErrorFallback;
