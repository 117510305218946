import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'services/reduxStore';

export interface NotificationState {
  targetTab: number;
  targetRoute: string;
  isRouteBlocked: boolean;
  isNotifyModalOpen: boolean;
  isTabChanged: boolean;
  targetPlaceName: string;
  targetPlaceId: string;
}

const initialState: NotificationState = {
  targetTab: 0,
  targetRoute: '',
  isRouteBlocked: false,
  isNotifyModalOpen: false,
  isTabChanged: false,
  targetPlaceName: '',
  targetPlaceId: '',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setTargetPlaceName: (state, action: PayloadAction<string>) => {
      state.targetPlaceName = action.payload;
    },
    setTargetPlaceId: (state, action: PayloadAction<string>) => {
      state.targetPlaceId = action.payload;
    },
    setRouteBlocked: (state, action: PayloadAction<boolean>) => {
      state.isRouteBlocked = action.payload;
    },
    setTargetRoute: (state, action: PayloadAction<string>) => {
      state.targetRoute = action.payload;
    },
    setNotifyModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isNotifyModalOpen = action.payload;
    },
    setTargetTab: (state, action: PayloadAction<number>) => {
      state.targetTab = action.payload;
    },
    setTabChanged: (state, action: PayloadAction<boolean>) => {
      state.isTabChanged = action.payload;
    },
  },
});

export const selectNotificationConfiguration = (state: RootState) => state.notification;

export const {
  actions: {
    setRouteBlocked,
    setTargetRoute,
    setTargetPlaceId,
    setTargetPlaceName,
    setNotifyModalOpen,
    setTabChanged,
    setTargetTab,
  },
} = notificationSlice;

export default notificationSlice.reducer;
