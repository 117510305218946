import {css} from 'styled-components';

export const RobotoFont = css`
  font-family: 'Roboto', sans-serif;
`;

export const MontserratFont = css`
  font-family: 'Montserrat', sans-serif;
`;

export const regularWeight = css`
  font-weight: 400;
`;

export const mediumWeight = css`
  font-weight: 500;
`;

export const boldWeight = css`
  font-weight: 700;
`;

export const font_body_small = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 0.75rem;
`;

export const font_body_medium = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 0.875rem;
`;

export const font_body_large = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 1rem;
`;

export const font_label_small = css`
  ${RobotoFont}
  ${mediumWeight}
  font-size: 0.6875rem;
  letter-spacing: 0.5px;
`;

export const font_label_medium = css`
  ${RobotoFont}
  ${mediumWeight}
  font-size: 0.75rem;
`;

export const font_label_large = css`
  ${RobotoFont}
  ${mediumWeight}
  font-size: 0.875rem;
`;

export const font_title_small = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 0.875rem;
`;

export const font_title_medium = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 1rem;
`;

export const font_title_large = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 1.375rem;
`;

export const font_headline_small = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 1.5rem;
`;

export const font_headline_medium = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 1.75rem;
`;

export const font_headline_large = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 2rem;
`;

export const font_display_small = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 2.25rem;
`;

export const font_display_medium = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 2.8125rem;
`;

export const font_display_large = css`
  ${RobotoFont}
  ${regularWeight}
  font-size: 3.5625rem;
`;
