export default {
  ERROR_TITLE: 'Something went wrong:',
  BUTTON_TEXT: 'Try again',
  CODE: 'Code from sms',
  CODE_HINT: 'Enter confirmation code from sms',
  TEL: 'Telephone number',
  PASSWORD: 'Password',
  TEL_ERROR: 'Invalid phone number',
  PASS_ERROR:
    'Make sure that the password has 8 characters, including number, uppercase letter and special character (!, ?, ...)',
  PASS_CONFIRM_ERROR: 'Passwords must match',
  NEXT: 'Next',
  BACK_BTN: 'Back',
  AGAIN: 'Send again',
  RIGHTS: '© 2024 Vaiter. All rights reserved',
  ESTABLISHMENT: 'Main place',
  SETTINGS: 'Settings',
  CONFIG: 'Configuration',
  MENU: 'Menu',
  MENU_TITLE: 'Menu of network',
  PRICE_TYPE: 'Price types',
  MENU_POSITIONS: 'Menu positions',
  DEPARTMENT: 'Departments/workshop',
  DEPARTMENT_POSITIONS: 'Subdivisions',
  APPS: 'Appendices',
  EQUIP: 'Equipment',
  HALLS: 'Halls and tables',
  PERSONNEL: 'Personnel',
  PAYMENT_TYPE: 'Payment type',
  PLACE_MENU: 'Menu of place',
  REPORTS: 'Reports',
  MESSAGES: 'Messages',
  PROFILE: 'My profile',
  PROFILE_TITLE: 'Profile',
  MANAGERS: 'Managers',
  LOGOUT: 'Logout',
  ESTABL: 'Places',
  ESTABL_TITLE: 'Places of network',
  ORG: 'Organization',
  ORGS: 'Organizations',
  ORGS_TITLE: 'Organizations of network',
  SAVE: 'Save',
  CANCEL: 'Cancel',
  DELETE: 'Delete',
  OK: 'Accept',
  DELETE_PHOTO: 'Remove this photo?',
  DELETE_VIDEO: 'Remove this video?',
  DELETE_LINK: 'Remove this link?',
  CATEGORIES: 'To which category does the institution belong',
  CATEGORIES_PLACEHOLDER: 'Choose the categories that your places corresponds to',
  PRINTER: 'Printer',
  MENU_MAIN: 'General',
  POSITION: 'Positions',
  MODIFIERS: 'Modifiers',
  SECTION: 'Sections',
  SURE: 'Are you sure you want to leave this page? Unsaved data will be lost',
  YES: 'Yes',
  NO: 'No',
  SEND_AGAIN: 'Resend in',
  SECONDS: 'seconds',
  EMPTY_SECTION: 'Section is empty',
  NEW: 'New',
  DELIVERED: 'Delivered',
  PROCESSING: 'Processing',
  READY: 'Ready',
  SUBMITTED: 'Submitted',
  REFUSAL: 'Refusal',
  MEN: 'Male',
  WOMAN: 'Female',
  GIRL: 'Girl',
  BOY: 'Boy',
  PRE_CHECK: 'Precheck',
  PAID: 'Paid',
  PUNCHED: 'Punched',
  WORKER_MISTAKE: 'Worker error',
  DEPARTMENT_MISTAKE: 'Department error',
  CLIENT_REFUSAL: 'Client refusal',
  NO_OPTIONS: 'No Options',
  PREVIEW_HINT_SIZE: 'It is recommended to use horizontal images, the optimal aspect ratio is 16:9',
  NAME: 'Name',
  CONFIRM_CHANGE: 'Confirm the number change',
  SEND_CODE: 'The code was sent to the number ',
  CONFIRM: 'Confirm',
};
