import React, {ChangeEvent, FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Link, Outlet, useLocation, useNavigate} from 'react-router-dom';
import styled, {createGlobalStyle} from 'styled-components';
import BaseImage from 'components/BaseComponents/BaseImage';
import BaseLoader from 'components/BaseComponents/BaseLoader';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import BaseSeparatorLine from 'components/BaseComponents/BaseSeparatorLine';
import BaseSelector from 'components/BaseComponents/BaseSelector';
import Header from 'components/GeneralComponents/Header';
import {useAppDispatch, useAppSelector} from 'services/hooks';
import {selectMenusConfiguration, setMenuId, setMenuName, setPositionId} from 'store/menusSlice';
import {selectUserConfiguration} from 'store/userSlice';
import {initializeNetwork} from 'store/middleware/userThunk';
import {globalLocalization} from 'services/GlobalUtils';
import {getMenus, MenuProps} from 'pages/Menus/MenusUtils';
import {ReactComponent as Menu} from 'assets/icons/tab_access.svg';
import {ReactComponent as Close} from 'assets/icons/close.svg';
import Logo from 'assets/images/logo-text.webp';
import {font_body_small, font_label_large} from 'theme/fonts';

interface PlaceProps {
  value: string;
  label: string;
  icon: string;
}

const MenuPageLayout: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const {language, networkId} = useAppSelector(selectUserConfiguration);
  const {selectedMenuId} = useAppSelector(selectMenusConfiguration);
  const [isLoading, setLoading] = useState<boolean>(true);
  const [menusOptions, setMenusOptions] = useState<PlaceProps[]>([]);
  const [choosingMenu, setChoosingMenu] = useState<string>('');
  const [isVisible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    dispatch(initializeNetwork());
    dispatch(setPositionId(''));
  }, [dispatch]);

  useEffect(() => {
    const token = localStorage.getItem('BearerToken');
    !token && navigate('/auth');
    token && !selectedMenuId && navigate('/menus');
    token && !choosingMenu &&
      networkId &&
      getMenus(networkId, 1, 100, 'asc', language).then(({data: {data}}) => {
        if (data.length === 0) {
          navigate('/menus');
        } else {
          const filteredMenu = data.map(({id, translations}: MenuProps) => ({
            label: translations[language].name,
            value: id,
          }));
          setMenusOptions(filteredMenu);
          // TODO Drop set to localstorage resolve with customer
          setChoosingMenu(selectedMenuId);
        }
        setLoading(false);
      });
    window.scrollTo({top: 0, left: 0});
  }, [choosingMenu, dispatch, language, navigate, networkId, selectedMenuId]);

  const handleMenuChange = useCallback(
    ({target: {value}}: ChangeEvent<HTMLInputElement>) => {
      dispatch(setMenuId(value));
      dispatch(setMenuName(menusOptions.find((item) => item.value === value)?.label || ''));
      dispatch(setPositionId(''));
      setChoosingMenu(value);
      navigate('/menu-settings');
    },
    [dispatch, menusOptions, navigate]
  );

  const {MENU_MAIN, POSITION, MODIFIERS} = globalLocalization(language);

  const menuLinks = useMemo(
    () => [
      {
        to: '/menu-settings',
        text: MENU_MAIN,
      },
      {
        to: '/menu-positions',
        text: POSITION,
      },
      {
        to: '/menu-modifiers',
        text: MODIFIERS,
      },
    ],
    [MENU_MAIN, MODIFIERS, POSITION]
  );

  return isLoading ? (
    <Loader />
  ) : (
    <Wrapper>
      <Header setVisible={setVisible} hasBurger />
      <Main>
        <MenuSideBar isvisible={isVisible ? 1 : 0}>
          <SidebarHeader>
            <LogoImage src={Logo} />
            <CloseIcon icon={<Close />} onClick={() => setVisible(false)} />
          </SidebarHeader>
          <MenuSelectDropdown />
          <MenuItems>
            {menusOptions.length ? (
              <Selector>
                <Icon icon={<Menu />} />
                <MenuSelect onChange={handleMenuChange} options={menusOptions} selectValue={choosingMenu} />
              </Selector>
            ) : (
              <Loader />
            )}
            {menuLinks.map(({to, text}) => (
              <MenuItem key={text} to={to} isactive={pathname === to ? 1 : 0} onClick={() => setVisible(false)}>
                {text}
              </MenuItem>
            ))}
          </MenuItems>
        </MenuSideBar>
        <SeparatorLine />
        <Container>
          <Content>
            <Outlet />
          </Content>
        </Container>
      </Main>
      <MenuShadow isvisible={isVisible ? 1 : 0} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  color: ${({theme}) => theme.colors.gray100};
  background-color: ${({theme}) => theme.colors.gray010};
  padding-bottom: 2rem;
`;

const Main = styled.div`
  display: block;
  min-height: calc(100vh - 6.5rem);
  margin: 0;
  border-radius: 1.5rem;
  background-color: ${({theme}) => theme.colors.gray000};

  @media ${({theme}) => theme.breakpoints.minTb} {
    margin: 0 1.5rem;
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: grid;
    grid-template-columns: 15.625rem 1px auto;
  }
`;

const MenuItem = styled(Link)<{isactive: number}>`
  color: ${({theme}) => theme.colors.gray070};
  border-left: 0.25rem solid transparent;
  text-decoration: none;
  display: grid;
  align-items: center;
  grid-template-columns: 1.25rem 1fr;
  grid-column-gap: 1.25rem;
  height: 3.125rem;
  padding-left: 1rem;

  ${({
    isactive,
    theme: {
      colors: {green060, gray100},
    },
  }) =>
    isactive &&
    `
    border-left-color: ${green060};
    color: ${gray100};
  `}
`;

const MenuSideBar = styled.div<{isvisible: number}>`
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 22rem;
  padding: 2rem 0 0;
  transform: translate3d(-${({isvisible}) => (isvisible ? 0 : 100)}%, 0, 0);
  transition: transform 0.3s ease-out;
  background-color: ${({theme}) => theme.colors.gray000};

  & > ${MenuItem} {
    &:nth-last-child(2) {
      margin: 1.6875rem 0 0.625rem;
    }
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    z-index: 0;
    position: initial;
    height: initial;
    width: initial;
    padding: 3rem 0 3.75rem;
    background: transparent;
    transform: none;
  }
`;

const MenuItems = styled.div`
  height: calc(100vh - 12rem);
  overflow-y: auto;
`;

const SidebarHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 0 1rem 1.5rem;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: none;
  }
`;

const LogoImage = styled(BaseImage)`
  height: 3rem;
`;

const CloseIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    padding: 0.375rem;
    border-radius: 0.75rem;
    border: 1px solid ${({theme}) => theme.colors.gray020};
    fill: ${({theme}) => theme.colors.gray100};
  }
`;

const MenuShadow = styled.div<{isvisible: number}>`
  position: fixed;
  z-index: 4;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: ${({theme}) => theme.colors.gray100};
  pointer-events: ${({isvisible}) => (isvisible ? 'none' : 'initial')};
  transform: scale3d(${({isvisible}) => (isvisible ? '1, 1' : '0, 0')}, 1);
  opacity: ${({isvisible}) => (isvisible ? 0.5 : 0)};
  transition: opacity 0.3s ease-in, transform 0s linear ${({isvisible}) => (isvisible ? 0 : 0.3)}s;
`;

const Container = styled.div`
  max-width: 100%;
  padding: 1.25rem 1rem 3.75rem;
  box-sizing: border-box;
  overflow: hidden;

  @media ${({theme}) => theme.breakpoints.minTb} {
    padding: 2rem 1.5rem 3.75rem;
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    padding: 3rem 1.5rem 3.75rem;
    margin: 0.75rem 0 1.25rem;
  }
`;

const Content = styled.div`
  width: 100%;
  margin: 0 auto;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    width: clamp(677px, 94%, 1440px);
  }
`;

const SeparatorLine = styled(BaseSeparatorLine)`
  display: none;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: block;
    height: 100%;
    width: 1px;
    background-color: ${({theme}) => theme.colors.gray020};
    border: 0;
  }
`;

const Icon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    padding: 0.625rem;
    border-radius: 0.75rem;
    fill: ${({theme}) => theme.colors.gray000};
    background-color: ${({theme}) => theme.colors.green060};
    width: 1.25rem;
    height: 1.25rem;
  }
`;

const Loader = styled(BaseLoader)`
  display: flex;
  margin: 20% auto;
`;

const Selector = styled.div`
  display: flex;
  padding: 0 1.5rem;
`;

const MenuSelect = styled(BaseSelector)`
  &.MuiFormControl-root {
    background-color: ${({theme}) => theme.colors.gray010};
    width: 100%;
    margin: 0 0.25rem 1.5rem;
    padding: 0.5rem;
    border-radius: 1rem;

    .MuiSelect-select {
      ${font_label_large};
      padding: 0;
    }

    fieldset {
      border: none;
    }
  }
`;

const MenuSelectDropdown = createGlobalStyle`
  li.MuiMenuItem-root {
    ${font_body_small};
    padding: 0.375rem 0.5rem;
  }
`;

export default MenuPageLayout;
