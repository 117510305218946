import React, {ReactNode} from 'react';
import styled from 'styled-components';
import {createFilterOptions} from '@mui/material/Autocomplete';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {LocalizationObjProps, place} from 'services/GlobalUtils';
import en from './localization/EN';
import uk from './localization/UK';
import {ReactComponent as NoCategory} from 'assets/icons/no_category.svg';
import {ReactComponent as Avocado} from 'assets/icons/avocado.svg';
import {ReactComponent as Beer} from 'assets/icons/beer.svg';
import {ReactComponent as Bottle} from 'assets/icons/bottle.svg';
import {ReactComponent as Cheese} from 'assets/icons/cheese.svg';
import {ReactComponent as Coffee} from 'assets/icons/coffee.svg';
import {ReactComponent as Cook} from 'assets/icons/cook.svg';
import {ReactComponent as Crab} from 'assets/icons/crab.svg';
import {ReactComponent as Cream} from 'assets/icons/cream.svg';
import {ReactComponent as Croissant} from 'assets/icons/croissant.svg';
import {ReactComponent as Dumpling} from 'assets/icons/dumpling.svg';
import {ReactComponent as Fries} from 'assets/icons/fries.svg';
import {ReactComponent as Fruits} from 'assets/icons/fruits.svg';
import {ReactComponent as Hookah} from 'assets/icons/hookah.svg';
import {ReactComponent as Hotdog} from 'assets/icons/hotdog.svg';
import {ReactComponent as Ice} from 'assets/icons/ice.svg';
import {ReactComponent as Icecream} from 'assets/icons/icecream.svg';
import {ReactComponent as Jelly} from 'assets/icons/jelly.svg';
import {ReactComponent as Kitchener} from 'assets/icons/kitchener.svg';
import {ReactComponent as Lemonade} from 'assets/icons/lemonade.svg';
import {ReactComponent as Marshmallow} from 'assets/icons/marshmallow.svg';
import {ReactComponent as New} from 'assets/icons/new.svg';
import {ReactComponent as Pepper} from 'assets/icons/pepper.svg';
import {ReactComponent as Pie} from 'assets/icons/pie.svg';
import {ReactComponent as Puding} from 'assets/icons/puding.svg';
import {ReactComponent as Riсe} from 'assets/icons/riсe.svg';
import {ReactComponent as Sausage} from 'assets/icons/sausage.svg';
import {ReactComponent as Shawarma} from 'assets/icons/shawarma.svg';
import {ReactComponent as Snowflake} from 'assets/icons/snowflake.svg';
import {ReactComponent as Tea} from 'assets/icons/tea.svg';
import {ReactComponent as Toast} from 'assets/icons/toast.svg';
import {ReactComponent as Vat} from 'assets/icons/vat.svg';
import {ReactComponent as Watermelon} from 'assets/icons/watermelon.svg';
import {ReactComponent as Wine} from 'assets/icons/wine.svg';
import {ReactComponent as Glass} from 'assets/icons/glass.svg';
import {ReactComponent as Cup} from 'assets/icons/cup.svg';
import {ReactComponent as Juice} from 'assets/icons/juice.svg';
import {ReactComponent as Burger} from 'assets/icons/burger.svg';
import {ReactComponent as Donut} from 'assets/icons/donut.svg';
import {ReactComponent as Omelet} from 'assets/icons/omelet.svg';
import {ReactComponent as Fish} from 'assets/icons/fish.svg';
import {ReactComponent as Hamon} from 'assets/icons/hamon.svg';
import {ReactComponent as Meat} from 'assets/icons/meat.svg';
import {ReactComponent as Noodles} from 'assets/icons/noodles.svg';
import {ReactComponent as Sandwich} from 'assets/icons/sandwich.svg';
import {ReactComponent as Pizza} from 'assets/icons/pizza.svg';
import {ReactComponent as Salad} from 'assets/icons/salad.svg';
import {ReactComponent as Soup} from 'assets/icons/soup.svg';
import {ReactComponent as Sushi} from 'assets/icons/sushi.svg';
import {ReactComponent as Slicing} from 'assets/icons/slicing.svg';

const localizationObj = {en, uk} as LocalizationObjProps;
export const localization = (language: string) => localizationObj[language];

// ------------- First tab utils ------------- //

export interface TranslationsProps {
  [key: string]: {
    id: string | number;
    locale: string;
    name: string;
  };
}

interface GroupsProps {
  createdAt: string;
  discount: number;
  id: string | number;
  image: string;
  isActive: boolean;
  translations: TranslationsProps;
}

interface PhotosProps {
  createdAt: string;
  id: string | number;
  isActive: boolean;
  mimeType: string;
  name: string;
  orderNum: number;
  path: string;
  size: number;
}

interface YoutubeProps {
  id: string | number;
  link: string;
  name: string;
  code: string;
  metadata: string[];
  isActive: boolean;
  createdAt: string;
  orderNum: number;
}

interface TaxesProps {
  calculation: string;
  id: string | number;
  isActive: boolean;
  name: string;
  rate: number;
  type: {id: string | number; name: string; isActive: boolean};
}

interface UnitProps {
  id: string | number;
  isActive: boolean;
  isInteger: boolean;
  multiplicity: number;
  orderNum: number;
  translations: TranslationsProps;
}

export interface DepartmentProps {
  id: string;
  department: {
    id: string;
    name: string;
    isActive: boolean;
    isRegistry: boolean;
  };
  isActive: boolean;
}

export interface MenuItemsProps {
  calories: number;
  cookingTime: number;
  cost: number;
  createdAt: string;
  discount: number;
  id: string;
  isActive: boolean;
  orderNum: number;
  price: number;
  quantity: number;
  weight: number;
  groups: GroupsProps[];
  itemHasDepartments: DepartmentProps[];
  itemHasPhotos: PhotosProps[];
  itemHasYouTubes: YoutubeProps[];
}

export interface MenuProps {
  createdAt: string;
  id: string;
  isActive: boolean;
  menuHasItems: MenuItemsProps[];
  taxes: TaxesProps[];
  unit: UnitProps;
  translations: TranslationsProps;
}

export interface MenuListProps {
  isLoading: boolean;
  isAddMode: boolean;
  page: number;
  pageCount: number;
  total: number;
  rowsPerPage: number;
  orderByName: 'asc' | 'desc';
  menu: MenuProps[];
}

export interface MenuDetailsInitialStateProps {
  tabNumber: number;
  nameUK: string;
  nameEN: string;
  createdMenuId: string;
  isSuccess: boolean;
}

// Get list of menu
export const getMenus = (networkId: string, page: number, itemsPerPage: number, orderByName: string, locale: string) =>
  place
    .get(
      `/menus/${networkId}/list?page=${page}&itemsPerPage=${itemsPerPage}&order%5Btranslations.name%5D=${orderByName}&order_locale=${locale}`
    )
    .then((data) => data);

// Update Menu Details
export const updateMenuDetails = (id: string | number, isActive: boolean) =>
  place.put(`/menus/${id}`, {isActive}).then((data) => data);

export const createMenu = (network: string, nameUK: string, nameEN: string) =>
  place
    .post('/menus', {
      network,
      translations: {
        uk: {name: nameUK, locale: 'uk'},
        en: {name: nameEN || nameUK, locale: 'en'},
      },
    })
    .then((data) => data);

export const menuDetailsInitialState: MenuDetailsInitialStateProps = {
  tabNumber: 0,
  nameUK: '',
  nameEN: '',
  createdMenuId: '',
  isSuccess: false,
};

export const menusListInitialState: MenuListProps = {
  isLoading: true,
  isAddMode: false,
  page: 1,
  pageCount: 1,
  total: 0,
  rowsPerPage: 25,
  orderByName: 'asc',
  menu: [],
};

// ------------- First tab utils ------------- //

// ------------- Second tab utils ------------- //

export interface GroupPositionProps {
  id: string;
  orderNum: number;
}

export interface MenuChildrenAddEditProps {
  id: string;
  discount?: string | number;
  icon: string;
  isActive?: boolean;
  translations: TranslationsProps;
  children?: MenuChildrenAddEditProps[];
}

export interface MenuAddEditProps {
  id: string;
  discount: string | number;
  icon: string;
  isActive: boolean;
  hasChildren: boolean;
  children: MenuChildrenAddEditProps[];
  translations: TranslationsProps;
  parent: MenuChildrenAddEditProps | undefined;
}

export interface MenuInitialStateProps {
  id: string;
  title: string;
  nameUK: string;
  translationIdUK: string | number;
  nameEN: string;
  translationIdEN: string | number;
  discount: string | number;
  isActive: boolean;
  isActiveDisabled: boolean;
  hasChildren: boolean;
  parent: MenuChildrenAddEditProps | undefined;
  children: null | undefined | MenuChildrenAddEditProps;
  icon: string;
}

export interface DetailsInitialStateProps {
  tabNumber: number;
  isModalOpen: boolean;
  isError: boolean;
  errorMessage: string;
  isDiscountWrong: boolean;
  parentOptions: MenuChildrenAddEditProps | null;
}

export const menuInitialState: MenuInitialStateProps = {
  id: '',
  title: '',
  nameUK: '',
  translationIdUK: '',
  nameEN: '',
  translationIdEN: '',
  discount: 100,
  isActive: true,
  isActiveDisabled: false,
  hasChildren: false,
  parent: undefined,
  children: null,
  icon: '',
};

export const detailsInitialState: DetailsInitialStateProps = {
  tabNumber: 0,
  isModalOpen: false,
  isError: false,
  errorMessage: '',
  isDiscountWrong: false,
  parentOptions: null,
};

// Get sections tree
export const getSectionsTree = (networkId: string, locale: string, orderByName: string) =>
  place
    .get(`/menu/groups/${networkId}/tree?order%5Btranslations.name%5D=${orderByName}&order_locale=${locale}`)
    .then(({data: {data}}) => data);

// Get sections tree list
export const getSectionsList = (networkId: string, locale: string) =>
  place
    .get(
      `/menu/groups/${networkId}/list?page=1&itemsPerPage=100&order%5Btranslations.name%5D=asc&exists%5Bparent%5D=false&order_locale=${locale}`
    )
    .then(({data: {data}}) => data);

// Get group menu details
export const getGroupMenuDetails = (menuId: string | number) =>
  place.get(`/menu/groups/${menuId}`).then(({data}) => data);

// Update positions of group menu
export const updateGroupMenuPositions = (positions: {orderNum: number; id: string | number}[]) =>
  place.post('/menu/groups/positions', {positions}).then(({data}) => data);

// Update status of group menu
export const updateStatusGroupMenu = (menuId: string | number, isActive: boolean) =>
  place.put(`/menu/groups/${menuId}`, {isActive}).then(({data}) => data);

// Update status of group menu
export const removeMenu = (menuId: string) => place.delete(`/menu/groups/${menuId}`);

export const createGroupMenu = ({network, parent, icon, isActive, discount, nameUK, nameEN}: any) =>
  place
    .post('/menu/groups', {
      network,
      parent: parent === 'no_category' ? null : parent,
      icon: icon === 'no_category' ? null : icon,
      discount,
      isActive,
      translations: {
        uk: {name: nameUK, locale: 'uk'},
        en: {name: nameEN || nameUK, locale: 'en'},
      },
    })
    .then((data) => data);

export const updateGroupMenu = ({
  id,
  parent,
  icon,
  isActive,
  discount,
  nameUK,
  nameEN,
  translationIdUK,
  translationIdEN,
}: any) =>
  place
    .put(`/menu/groups/${id}`, {
      parent: parent === 'no_category' ? null : parent,
      icon: icon === 'no_category' ? null : icon,
      discount,
      isActive,
      translations: {
        uk: {id: translationIdUK, name: nameUK, locale: 'uk'},
        en: {id: translationIdEN, name: nameEN || nameUK, locale: 'en'},
      },
    })
    .then((data) => data);

export const getIconType = (type: string) => {
  const target: {[key: string]: ReactNode} = {
    no_category: <GrayIcon icon={<NoCategory />} />,
    glass: <BlueIcon icon={<Glass />} />,
    cup: <GreenIcon icon={<Cup />} />,
    juice: <RedIcon icon={<Juice />} />,
    burger: <BlueIcon icon={<Burger />} />,
    donut: <GreenIcon icon={<Donut />} />,
    omelet: <YellowIcon icon={<Omelet />} />,
    fish: <RedIcon icon={<Fish />} />,
    hamon: <GreenIcon icon={<Hamon />} />,
    meat: <RedIcon icon={<Meat />} />,
    noodles: <BlueIcon icon={<Noodles />} />,
    sandwich: <YellowIcon icon={<Sandwich />} />,
    pizza: <RedIcon icon={<Pizza />} />,
    salad: <BlueIcon icon={<Salad />} />,
    soup: <YellowIcon icon={<Soup />} />,
    sushi: <GreenIcon icon={<Sushi />} />,
    slicing: <GreenIcon icon={<Slicing />} />,
    avocado: <GreenIcon icon={<Avocado />} />,
    beer: <YellowIcon icon={<Beer />} />,
    bottle: <YellowIcon icon={<Bottle />} />,
    cheese: <YellowIcon icon={<Cheese />} />,
    coffee: <GreenIcon icon={<Coffee />} />,
    cook: <BlueIcon icon={<Cook />} />,
    crab: <RedIcon icon={<Crab />} />,
    cream: <YellowIcon icon={<Cream />} />,
    croissant: <GreenIcon icon={<Croissant />} />,
    dumpling: <YellowIcon icon={<Dumpling />} />,
    fries: <BlueIcon icon={<Fries />} />,
    fruits: <GreenIcon icon={<Fruits />} />,
    hookah: <BlueIcon icon={<Hookah />} />,
    hotdog: <YellowIcon icon={<Hotdog />} />,
    ice: <GreenIcon icon={<Ice />} />,
    icecream: <BlueIcon icon={<Icecream />} />,
    jelly: <YellowIcon icon={<Jelly />} />,
    kitchener: <YellowIcon icon={<Kitchener />} />,
    lemonade: <RedIcon icon={<Lemonade />} />,
    marshmallow: <RedIcon icon={<Marshmallow />} />,
    new: <RedIcon icon={<New />} />,
    pepper: <BlueIcon icon={<Pepper />} />,
    pie: <YellowIcon icon={<Pie />} />,
    puding: <RedIcon icon={<Puding />} />,
    riсe: <GreenIcon icon={<Riсe />} />,
    sausage: <RedIcon icon={<Sausage />} />,
    shawarma: <BlueIcon icon={<Shawarma />} />,
    snowflake: <BlueIcon icon={<Snowflake />} />,
    tea: <BlueIcon icon={<Tea />} />,
    toast: <YellowIcon icon={<Toast />} />,
    vat: <BlueIcon icon={<Vat />} />,
    watermelon: <RedIcon icon={<Watermelon />} />,
    wine: <RedIcon icon={<Wine />} />,
  };
  return target[type] || <GrayIcon icon={<NoCategory />} />;
};

const BlueIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    fill: ${({theme}) => theme.colors.blue050};
  }
`;

const GreenIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    fill: ${({theme}) => theme.colors.green050};
  }
`;

const RedIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    fill: ${({theme}) => theme.colors.red050};
  }
`;

const YellowIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    fill: ${({theme}) => theme.colors.yellow050};
  }
`;

const GrayIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    fill: ${({theme}) => theme.colors.gray030};
  }
`;

// ------------- Second tab utils ------------- //

// ------------- Third tab utils ------------- //

export interface MenuItemsOptions {
  id: string;
  isActive?: boolean;
  price?: string | number;
  translations: TranslationsProps;
}

export interface PriceTypesInitialStateProps {
  id?: string;
  calculation: string;
  allowance: string;
  name: string;
  menuItems: MenuItemsOptions[];
  value: string;
  isActive: boolean;
  isForAll: boolean;
  isError?: boolean;
  errorMessage?: string;
}

export interface PriceTypesProps {
  id?: string;
  network?: string;
  calculation: string;
  allowance: string;
  name: string;
  menuItems: string[];
  value: string;
  isActive: boolean;
  isForAll: boolean;
}

export interface SearchOptions {
  id: string;
  value: string;
}

/**
 * -? optional minus (for negative numbers).
 * \d+ matches one or more digits (the integer part of a number).
 * (\.\d+)? is an optional group that matches a decimal point and one or more digits after it (the fractional part of a number).
 * The question mark indicates that the entire group is optional, that is, the numbers can be either with or without a fractional part.
 */
export const isFixPriceValueValidation = (value: string) => /^-?\d+(\.\d+)?$/.test(value.trim());

export const allowanceOptions = [
  {
    value: 'sum',
    label: '₴',
  },
  {
    value: 'percent',
    label: '%',
  },
];

export const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => (option as SearchOptions).value,
  trim: true,
  ignoreAccents: true,
  ignoreCase: true,
});

// Get price types tree list
export const getPriceTypeList = (
  networkId: string,
  locale: string,
  orderByName: string,
  page = 1,
  itemsPerPage = 100
) =>
  place
    .get(`/menu/prices/${networkId}/list?page=${page}&itemsPerPage=${itemsPerPage}&order%5Bname%5D=${orderByName}`)
    .then(({data: {data}}) => data);

// Get price types details
export const getPriceTypeDetails = (menuId: string | number) =>
  place.get(`/menu/prices/${menuId}`).then(({data}) => data);

// Create price type
export const createPriceType = ({
  network,
  calculation,
  allowance,
  name,
  menuItems,
  value,
  isActive,
  isForAll,
}: PriceTypesProps) =>
  place
    .post('/menu/prices', {
      network,
      calculation,
      allowance,
      name,
      menuItems,
      value,
      isActive,
      isForAll,
    })
    .then((data) => data);

export const updatePriceType = ({
  id,
  calculation,
  allowance,
  name,
  menuItems,
  value,
  isActive,
  isForAll,
}: PriceTypesProps) =>
  place
    .put(`/menu/prices/${id}`, {
      calculation,
      allowance,
      name,
      menuItems,
      value,
      isActive,
      isForAll,
    })
    .then((data) => data);

// Update price types status
export const updatePriceTypeStatus = (menuId: string | number, isActive: boolean) =>
  place.put(`/menu/prices/${menuId}`, {isActive}).then(({data}) => data);

// Delete price type
export const removePriceType = (menuId: string) => place.delete(`/menu/prices/${menuId}`);

export const priceTypesInitialState: PriceTypesInitialStateProps = {
  id: '',
  calculation: '',
  allowance: '',
  name: '',
  menuItems: [],
  value: '0',
  isActive: true,
  isForAll: true,
  isError: false,
  errorMessage: '',
};

// ------------- Third tab utils ------------- //

export interface SectionEntityInitialStateProps {
  isOpen: boolean;
  isActiveAll: boolean;
  isNotifyModalOpen: boolean;
  currentId: string;
  menuItems: MenuAddEditProps[];
}

export const sectionEntityInitialState: SectionEntityInitialStateProps = {
  isOpen: false,
  isActiveAll: false,
  isNotifyModalOpen: false,
  currentId: '',
  menuItems: [],
};
