import {createGlobalStyle} from 'styled-components';
import Colors from './colors';

const {gray000, gray100, green060} = Colors;

export const OverrideStyles = createGlobalStyle`
  // ------------- BaseNotification ------------- //
  .Toastify {
    &__toast {
      padding: 0 0 5px 0 !important;

      &-body {
        display: flex;
        background: ${gray000} !important;
        padding: 15px 20px 10px 15px !important;
        margin: 0 !important;
        align-items: center;
      }

      &-container--top-right {
        top: 0.8em !important;
        right: 0.8em !important;
      }
    }

    &__close-button {
      color: ${gray100} !important;
      opacity: .3 !important;
      position: absolute;
      top: 5px;
      right: 5px;
    }
  }

  // ------------- Base Selector ------------- //
  div.MuiSelect-select {
    display: flex;
    align-items: center;
  }

  li[class*='MuiMenuItem-root'] svg {
    margin-right: 8px;
  }

  // ------------- Base Input Errors ------------- //
  .MuiSwitch-root {
    & > span {
      &.Mui-checked {
        color: ${green060};

        & + .MuiSwitch-track {
          background-color: ${green060};
        }
      }
    }
  }

  // ------------- Base Switch ------------- //
  span.MuiSwitch-root {
    span.Mui-disabled {
      &.Mui-checked {
        color: rgb(130, 182, 152);
      }
    }
  }

  // ------------- Base Checkbox ------------- //
  span.MuiCheckbox-root {
    &.Mui-disabled {
      opacity: 0.7;
    }
  }

  // ------------- Base Checkbox ------------- //
  span.MuiCheckbox-root {
    &.Mui-disabled {
      opacity: 0.7;
    }
  }

  // ------------- Base Tabs ------------- //
  button.MuiTab-root.Mui-selected {
    color: ${gray100};

    svg.MuiSvgIcon-root {
      fill: ${gray100};
    }
  }

  span.MuiTabs-indicator {
    background-color: ${green060};
  }

  // ------------- Base Multi Autocomplete ------------- //
  .MuiAutocomplete-root {
    .MuiAutocomplete-tag.MuiChip-root {
      margin: 0;
    }
  }

  // ------------- Base Input Errors ------------- //
  .MuiFormHelperText-root {
    max-width: 21.5625rem;
  }

  // ------------- Pagination Buttons ------------- //
  li button {
    &.MuiPaginationItem-root {
      border: none;
      border-radius: 0.75rem;

      &.Mui-selected {
        background-color: ${green060};
        color: ${gray000};

        &:hover {
          background-color: ${green060};
        }
      }
    }
  }

  // ------------- Animations ------------- //
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes gradient {
    0% {
      background-position: 0 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }

  @keyframes hideError {
    0% {
      opacity: 0;
      transform: translateX(8px);
    }
    20% {
      opacity: 1;
      transform: translateX(0);
    }
    80% {
      opacity: 1;
      transform: translateX(0);
    }
    100% {
      opacity: 0;
      transform: translateX(8px);
    }
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 1;
    }
    25% {
      transform: scale(1.1);
      opacity: 0.7;
    }
    50% {
      transform: scale(1.2);
      opacity: 0.5;
    }
    75% {
      transform: scale(1.1);
      opacity: 0.7;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
`;
