import React, {Fragment, lazy} from 'react';
import {Route, createBrowserRouter, createRoutesFromElements} from 'react-router-dom';
import {routersArr} from './RoutersList';

const NotFoundView = lazy(() => import('pages/NotFound/NotFoundView'));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Fragment>
      {routersArr.map(({key, layout, routers}) => (
        <Route key={key} element={layout}>
          {routers.map(({path, element}) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
      ))}
      <Route path="*" element={<NotFoundView />} />
    </Fragment>
  )
);
