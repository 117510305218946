import styled from 'styled-components';

export default styled.div<{horizontal?: number; dotted?: boolean}>`
  border-color: ${({theme}) => theme.colors.gray020};
  border-style: ${({dotted}) => (dotted ? 'dotted' : 'solid')};
  border-width: 1px;

  ${({horizontal = 0}) =>
    horizontal
      ? `
        width: calc(100% - 2px);
        height: 0;
      `
      : `
        width: 0;
        height: 1.25rem;
      `}
`;
