import React, {FC} from 'react';
import styled from 'styled-components';

interface Props {
  speed?: number | string;
  height?: number | string;
  width?: number | string;
}

const BaseLoader: FC<Props> = ({speed = 0.75, height = 50, width = 50, ...other}) => (
  <Wrapper speed={speed} height={height} width={width} {...other} />
);

const Wrapper = styled.div<{speed: number | string; height: number | string; width: number | string}>`
  background: transparent;
  width: ${({width}) => width}px;
  height: ${({height}) => height}px;
  border-radius: 50%;
  border: 2px solid ${({theme}) => theme.colors.green060};
  border-bottom-color: transparent !important;
  display: inline-block;
  animation: spin ${({speed}) => speed}s 0s infinite linear;
`;

export default BaseLoader;
