export default {
  gray100: `rgb(21, 24, 25)`, // #151819
  gray090: `rgb(34, 39, 42)`, // #22272A
  gray080: `rgb(57, 66, 70)`, // #394246
  gray070: `rgb(80, 92, 98)`, // #505C62
  gray060: `rgb(103, 118, 126)`, // #67767E
  gray050: `rgb(129, 143, 152)`, // #818F98
  gray040: `rgb(157, 168, 175)`, // #9DA8AF
  gray030: `rgb(185, 193, 198)`, // #B9C1C6
  gray020: `rgb(213, 218, 221)`, // #D5DADD
  gray010: `rgb(241, 243, 244)`, // #F1F3F4
  gray000: `rgb(255, 255, 255)`, // #FFFFFF

  green090: `rgb(11, 50, 28)`, // #0B321C
  green080: `rgb(21, 92, 51)`, // #155C33
  green070: `rgb(30, 133, 74)`, // #1E854A
  green060: `rgb(39, 174, 96)`, // #27AE60
  green050: `rgb(55, 210, 121)`, // #37D279
  green040: `rgb(97, 220, 149)`, // #61DC95
  green030: `rgb(138, 229, 177)`, // #8AE5B1
  green020: `rgb(180, 238, 205)`, // #B4EECD
  green010: `rgb(222, 248, 233)`, // #DEF8E9

  blue090: `rgb(9, 37, 52)`, // #092534
  blue080: `rgb(16, 67, 96)`, // #104360
  blue070: `rgb(24, 98, 139)`, // #18628B
  blue060: `rgb(31, 128, 183)`, // #1F80B7
  blue050: `rgb(46, 156, 219)`, // #2E9CDB
  blue040: `rgb(90, 177, 227)`, // #5AB1E3
  blue030: `rgb(133, 198, 234)`, // #85C6EA
  blue020: `rgb(177, 218, 242)`, // #B1DAF2
  blue010: `rgb(220, 239, 249)`, // #DCEFF9

  yellow090: `rgb(72, 66, 9)`, // #484209
  yellow080: `rgb(118, 108, 15)`, // #766C0F
  yellow070: `rgb(163, 149, 20)`, // #A39514
  yellow060: `rgb(209, 190, 26)`, // #D1BE1A
  yellow050: `rgb(230, 212, 54)`, // #E6D436
  yellow040: `rgb(236, 222, 101)`, // #ECDE65
  yellow030: `rgb(241, 232, 146)`, // #F1E892
  yellow020: `rgb(247, 242, 191)`, // #F7F2BF
  yellow010: `rgb(253, 251, 237)`, // #FDFBED

  red090: `rgb(75, 11, 11)`, // #4B0B0B
  red080: `rgb(119, 18, 18)`, // #771212
  red070: `rgb(164, 25, 25)`, // #A41919
  red060: `rgb(208, 32, 32)`, // #D02020
  red050: `rgb(226, 65, 65)`, // #E24141
  red040: `rgb(233, 109, 109)`, // #E96D6D
  red030: `rgb(239, 153, 153)`, // #EF9999
  red020: `rgb(246, 198, 198)`, // #F6C6C6
  red010: `rgb(253, 242, 242)`, // #FDF2F2
};
