import React, {FC, useCallback, useEffect, useMemo, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import styled from 'styled-components';
import BaseImage from 'components/BaseComponents/BaseImage';
import BaseMenu from 'components/BaseComponents/BaseMenu';
import BaseTruncate from 'components/BaseComponents/BaseTruncate';
import BaseIcon from 'components/BaseComponents/BaseIcon';
import {selectUserConfiguration, setLanguage} from 'store/userSlice';
import {useAppDispatch, useAppSelector} from 'services/hooks';
import {getUserProfile, userLogout} from 'pages/Authorization/AuthorizationUtils';
import {globalLocalization} from 'services/GlobalUtils';
import Logo from 'assets/images/logo-text.webp';
import {ReactComponent as Logout} from 'assets/icons/logout.svg';
import {ReactComponent as Profile} from 'assets/icons/side_settings.svg';
import {ReactComponent as User} from 'assets/icons/user.svg';
import {ReactComponent as Arrow} from 'assets/icons/arrow_dropdown.svg';
import {ReactComponent as Establishment} from 'assets/icons/places.svg';
import {ReactComponent as Menu} from 'assets/icons/side_menu.svg';
import {ReactComponent as Organizations} from 'assets/icons/organizations.svg';
import {ReactComponent as Burger} from 'assets/icons/menu_burger.svg';
import {ReactComponent as Managers} from 'assets/icons/managers.svg';
import Lang_uk from 'assets/icons/language_uk.svg';
import Lang_en from 'assets/icons/language_en.svg';
import {font_title_small} from 'theme/fonts';

interface HeaderProps {
  setVisible?: (value: boolean) => void;
  hasBurger?: boolean;
}

const Header: FC<HeaderProps> = ({setVisible, hasBurger}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {pathname} = useLocation();
  const {language, isOwner} = useAppSelector(selectUserConfiguration);
  const [isScrolled, setScrolled] = useState<boolean>(false);
  const [avatar, setAvatar] = useState<string>('');
  const [name, setName] = useState<string>('');

  useEffect(() => {
    getUserProfile().then(({photo, firstName, lastName, language}) => {
      setName(`${firstName} ${lastName[0]}.`);
      setAvatar(photo);
      dispatch(setLanguage(language.toLowerCase()));
    });
  }, [dispatch]);

  const {PROFILE, LOGOUT, ESTABL, ORGS, MENU, MANAGERS} = globalLocalization(language);

  const userOptions = useMemo(
    () => [
      {
        item: PROFILE,
        icon: <DropdownIcon icon={<Profile />} />,
        action: () => navigate('/profile'),
      },
      ...(isOwner
        ? [{item: MANAGERS, icon: <DropdownIcon icon={<Managers />} />, action: () => navigate('/managers')}]
        : []),
      {
        item: LOGOUT,
        icon: <DropdownIcon icon={<Logout />} />,
        action: () => {
          userLogout().then(() => localStorage.clear());
          navigate('/auth');
        },
      },
    ],
    [LOGOUT, MANAGERS, PROFILE, isOwner, navigate]
  );

  const headerLinks = useMemo(
    () => [
      {
        to: '/places',
        item: <BaseIcon icon={<Establishment />} />,
        text: ESTABL,
      },
      {
        to: '/menus',
        item: <BaseIcon icon={<Menu />} />,
        text: MENU,
      },
      {
        to: '/organizations',
        item: <BaseIcon icon={<Organizations />} />,
        text: ORGS,
      },
    ],
    [ESTABL, MENU, ORGS]
  );

  const handleScroll = useCallback(() => {
    setScrolled(window.scrollY > 16);
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Wrapper isscrolled={isScrolled ? 1 : 0} hasburger={hasBurger ? 1 : 0}>
      <LogoImage src={Logo} hasburger={hasBurger ? 1 : 0} />
      {hasBurger && <MenuBurger icon={<Burger />} onClick={() => setVisible && setVisible(true)} />}
      <HeaderMenu>
        {headerLinks.map(({to, item, text}) => (
          <StyledLink key={to} to={to} isactive={pathname === to ? 1 : 0}>
            {item}
            <MenuLinkText>{text}</MenuLinkText>
          </StyledLink>
        ))}
      </HeaderMenu>
      <UserMenu options={userOptions} language={language}>
        {avatar ? <Image src={avatar} /> : <UserIcon icon={<User />} />}
        <Truncate>{name || 'User'}</Truncate>
        <ArrowIcon icon={<Arrow />} />
      </UserMenu>
    </Wrapper>
  );
};

const Wrapper = styled.div<{isscrolled: number; hasburger?: number}>`
  position: sticky;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 4.5rem;
  display: grid;
  grid-template-columns: ${({hasburger}) => (hasburger ? 2.5 : 8)}rem auto 3rem;
  grid-column-gap: 0.625rem;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;
  transition: background-color 0.3s ease-out;

  ${({isscrolled}) =>
    isscrolled &&
    `
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(8px);
    box-shadow: 0 1px 2px -1px rgba(0, 0, 0, 0.3);
  `};

  @media (min-width: 769px) {
    left: 1.5rem;
    right: 1.5rem;
    grid-column-gap: 1rem;
    grid-template-columns: 8rem ${({hasburger}) => (hasburger ? '2.5rem' : '')} auto min-content;
    width: calc(100% - 3rem);
    padding: 0 2rem;
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    grid-template-columns: 8rem auto min-content;
  }

  @media print {
    display: none;
  }
`;

const HeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LogoImage = styled(BaseImage)<{hasburger?: number}>`
  height: 3.2rem;

  @media ${({theme}) => theme.breakpoints.maxTb} {
    display: ${({hasburger}) => (hasburger ? 'none' : 'block')};
  }
`;

const MenuBurger = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    padding: 0.375rem;
    border-radius: 0.75rem;
    border: 1px solid ${({theme}) => theme.colors.gray020};
    fill: ${({theme}) => theme.colors.gray100};

    @media ${({theme}) => theme.breakpoints.minLtg} {
      display: none;
    }
  }
`;

const StyledLink = styled(Link)<{isactive: number}>`
  ${font_title_small};
  background-color: ${({
    isactive,
    theme: {
      colors: {yellow020},
    },
  }) => isactive && yellow020};
  color: ${({theme}) => theme.colors.gray100};
  padding: 0.5rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  margin-right: 0.625rem;
  text-decoration: none;

  @media ${({theme}) => theme.breakpoints.minTb} {
    padding: 0.375rem 1rem 0.375rem 0.625rem;
  }

  .MuiSvgIcon-root {
    width: 1.5rem;
    height: 1.5rem;

    @media ${({theme}) => theme.breakpoints.minTb} {
      margin-right: 0.375rem;
    }
  }
`;

const MenuLinkText = styled.div`
  max-width: 12rem;
  display: none;

  @media ${({theme}) => theme.breakpoints.minTb} {
    display: block;
  }
`;

const Truncate = styled(BaseTruncate)`
  max-width: 12rem;
  display: none;

  @media ${({theme}) => theme.breakpoints.minTb} {
    display: block;
  }
`;

const UserMenu = styled(BaseMenu)<{language: string}>`
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 0.75rem;
    height: 0.75rem;
    left: 2rem;
    top: 0;
    background-image: url('${({language}) => (language === 'uk' ? Lang_uk : Lang_en)}');
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    border: 1px solid ${({theme}) => theme.colors.gray000};
    border-radius: 1rem;
  }
`;

const Image = styled(BaseImage)`
  object-fit: cover;
  margin-right: 0.5rem;
  border-radius: 0.75rem;
  width: 2.85rem;
  height: 2.85rem;
`;

const UserIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    background-color: ${({theme}) => theme.colors.green010};
    fill: ${({theme}) => theme.colors.green060};
    padding: 0.5rem;
    margin-right: 0.5rem;
    border-radius: 0.75rem;
    width: 1.85rem;
    height: 1.85rem;
  }
`;

const ArrowIcon = styled(BaseIcon).attrs({boxH: 18, boxW: 18})`
  &.MuiSvgIcon-root {
    display: none;

    @media ${({theme}) => theme.breakpoints.minTb} {
      display: block;
      fill: ${({theme}) => theme.colors.gray090};
      width: 1.125rem;
      height: 1.125rem;
    }
  }
`;

const DropdownIcon = styled(BaseIcon)`
  &.MuiSvgIcon-root {
    fill: ${({theme}) => theme.colors.gray070};
  }
`;

export default Header;
