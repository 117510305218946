export default {
  MENU_TITLE: 'Network menu',
  ADD: 'Add menu',
  ADD_NEW: 'Adding a menu',
  BACK_BTN: 'Return',
  ADD_TITLE: 'No menu has been added yet',
  ADD_SUBTITLE:
    'To add a menu to an establishment, go to the Menu tab in the header and add a new menu. After that, go back to the "Establishment Menu" and attach it to the institution',
  TITLE: 'Title',
  COUNT: 'Positions',
  ACTIVE: 'Active',
  NETWORK: 'Network',
  BACK_MENU: 'Back to menu list',
  TITLE_SUCCESS: 'Menu added!',
  SUBTITLE_SUCCESS:
    'Now you can go to menu settings: add categories, dishes and drinks, specify prices and weight. Or add another menu.',
  BACK_SETTINGS: 'To the settings',
  ADD_MORE: 'Add another menu',
  WAS: 'was',
  ACTIVATED: 'activated',
  DEACTIVATED: 'deactivated',
  ADD_SECTION: 'Add section',
  ADD_NEW_SECTION: 'Adding',
  ADD_NEW_PRICE_TYPE: 'Adding price type',
  ADD_PRICE_TYPE: 'Add type',
  EDIT: 'Editing',
  PLUG_TITLE: 'Add menu sections',
  PLUG_TITLE_PRICE_TYPE: 'Add price type',
  PLUG_SUBTITLE: 'Create sections and group menu items to make it easier for staff and patrons to find what they need.',
  PLUG_SUBTITLE_PRICE_TYPE:
    'Use price types to expand your pricing options. This will allow you to offer more flexible price terms to your network visitors',
  NAME: 'Name',
  MENU: 'Menu',
  SEARCH: 'Search',
  PH_NAME_PRICE_TYPE: 'For example "For staff"',
  GOODS: 'Goods',
  CALC: 'Price calculation method',
  PRICE_VALUE: 'Value',
  SELECT_TYPE: 'Choose the type of allowance',
  FOR_ALL_TEXT: 'For all products',
  UPDATED: 'menu updated successfully',
  DISCOUNT: 'Maximum discount, %',
  PARENT_CATEGORY: 'Parent category',
  ICON_CATEGORY: 'Category icon',
  SELECT_ICON: 'Select icon',
  DISCOUNT_ERROR: 'The value must be in the range 0 - 100',
  REMOVE: 'menu was successfully removed',
  REMOVE_PRICE_TYPE: 'type was successfully removed',
  WAS_SUCCESS: 'was successfully',
  WAS_SUCCESS_PRICE_TYPE: 'type was successfully',
  CREATE: 'added',
  UPDATE: 'updated',
  DELETE_TOOLTIP: 'This partition cannot be deleted because there are other partitions subordinate to it.',
  PASSIVE_TOOLTIP: 'This partition cannot be modified because partition management is turned off.',
  SWITCH_TOOLTIP:
    'his partition cannot be modified because partition management is turned off or there are other active partitions subordinate to it.',
  FOR_ALL: 'All',
  SELECT_ALL: 'Add all',
  SELECTED: 'Selected',
  FIXED: 'Fixed price',
  BASIC: 'From the basic price',
  COST: 'From Cost',
  MARKUP: 'From % markup',
  UPDATED_TYPE: 'type updated successfully',
  VALUE_ERR: 'Value should be number',
  NOTIFY_ACTIVE: 'Activate all subordinate sections?',
  NOTIFY_DEACTIVE:
    'Disabling the activity of the parent section will automatically deactivate all its subordinate sections! Continue?',
};
