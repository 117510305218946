import React, {FC, useState, useCallback, ReactNode, MouseEvent} from 'react';
import styled from 'styled-components';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface Props {
  options: OptionsProps[];
  children: ReactNode;
}

export interface OptionsProps {
  item: ReactNode;
  action?: (item: any) => void;
  icon?: ReactNode;
}

const BaseMenu: FC<Props> = ({options, children, ...other}) => {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleClick = useCallback(
    ({currentTarget}: MouseEvent<HTMLDivElement>) => {
      anchorEl !== currentTarget && setAnchorEl(currentTarget);
    },
    [anchorEl]
  );

  const onMouseLeave = useCallback(() => setAnchorEl(null), []);

  return (
    <>
      <Face onClick={handleClick} {...other}>
        {children}
      </Face>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onMouseLeave}
        MenuListProps={{onMouseLeave}}
        autoFocus={false}
      >
        <MenuItemWrapper>
          {options.map(({item, action, icon}, index) => (
            <MenuItem key={index} data-attr={typeof item === 'string' ? item : null} onClick={action}>
              {icon && icon}
              {item}
            </MenuItem>
          ))}
        </MenuItemWrapper>
      </Menu>
    </>
  );
};

const Face = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const MenuItemWrapper = styled.div`
  padding: 0.5rem;
`;

export default BaseMenu;
