export default {
  ERROR_TITLE: 'Щось пішло не так:',
  BUTTON_TEXT: 'Спробуйте знову',
  CODE: 'Код із смс',
  CODE_HINT: 'Введіть код підтвердження із смс',
  TEL: 'Номер телефону',
  PASSWORD: 'Пароль',
  TEL_ERROR: 'Неправильний номер телефону',
  PASS_ERROR:
    'Має мати ≥ 8 символів латинецею, одне число, символ у верхньому регістрі та один спец. символ (!, ?, ...)',
  PASS_CONFIRM_ERROR: 'Паролі мають співпадати',
  NEXT: 'Далі',
  BACK_BTN: 'Повернутися',
  AGAIN: 'Надіслати ще раз',
  RIGHTS: '© 2024 Vaiter. Всі права захищено',
  ESTABLISHMENT: 'Головна закладу',
  SETTINGS: 'Налаштування',
  CONFIG: 'Конфігурація',
  MENU: 'Меню',
  MENU_TITLE: 'Меню мережі',
  PRICE_TYPE: 'Типи цін',
  MENU_POSITIONS: 'Позиції меню',
  DEPARTMENT: 'Відділи/цеха',
  DEPARTMENT_POSITIONS: 'Підрозділи',
  APPS: 'Додатки',
  EQUIP: 'Обладнання',
  HALLS: 'Зали й столи',
  PERSONNEL: 'Персонал',
  PAYMENT_TYPE: 'Види оплат',
  PLACE_MENU: 'Меню закладу',
  REPORTS: 'Звіти',
  MESSAGES: 'Повідомлення',
  PROFILE: 'Мій профіль',
  PROFILE_TITLE: 'Профайл',
  MANAGERS: 'Менеджери',
  LOGOUT: 'Вийти',
  ESTABL: 'Заклади',
  ESTABL_TITLE: 'Заклади мережі',
  ORG: 'Організація',
  ORGS: 'Організації',
  ORGS_TITLE: 'Організації мережі',
  SAVE: 'Зберегти',
  CANCEL: 'Скасувати',
  DELETE: 'Видалити',
  OK: 'Ознайомлений',
  DELETE_PHOTO: 'Видалити це фото?',
  DELETE_VIDEO: 'Видалити це відео?',
  DELETE_LINK: 'Видалити це посилання?',
  CATEGORIES: 'До якої категорії належить заклад',
  CATEGORIES_PLACEHOLDER: 'Оберіть категорії, яким відповідає ваш заклад',
  PRINTER: 'Принтер',
  MENU_MAIN: 'Загальне',
  POSITION: 'Позиції',
  MODIFIERS: 'Модифікатори',
  SECTION: 'Розділи',
  SURE: 'Ви дійсно хочете залишити сторінку? Незбережені дані будуть втрачені',
  YES: 'Так',
  NO: 'Ні',
  SEND_AGAIN: 'Надіслати ще раз через',
  SECONDS: 'cекунд',
  EMPTY_SECTION: 'Без роздiлу',
  NEW: 'Новий',
  DELIVERED: 'Доставлений',
  PROCESSING: 'Прийнятий в обробку',
  READY: 'Готовий',
  SUBMITTED: 'Поданий',
  REFUSAL: 'Відмова',
  MEN: 'Чоловік',
  WOMAN: 'Жінка',
  GIRL: 'Дівчина',
  BOY: 'Хлопець',
  PRE_CHECK: 'Пре-чек',
  PAID: 'Оплачено',
  PUNCHED: 'Чек пробито',
  WORKER_MISTAKE: 'Помилка працівника',
  DEPARTMENT_MISTAKE: 'Помилка відділу/цеху',
  CLIENT_REFUSAL: 'Відмова клієнта',
  NO_OPTIONS: 'Немає варіантів',
  PREVIEW_HINT_SIZE: 'Рекомендується використовувати горизонтальні зображення, оптимальне співвідношення сторін - 16:9',
  NAME: 'Назва',
  CONFIRM_CHANGE: 'Підтвердіть зміну номеру',
  SEND_CODE: 'Код надіслано на номер ',
  CONFIRM: 'Підтвердити',
};
