export default {
  PLACES: 'Заклади мережі',
  ADD: 'Додати заклад',
  TITLE: 'Назва',
  ADDRESS: 'Адреса',
  TEL: 'Телефон',
  ADD_TITLE: 'Додайте заклади мережі',
  ADD_SUBTITLE:
    'Після додавання закладу ви зможете налаштувати його меню, види оплат та схеми залів, а також підключити додатки, які використовуватимуться при замовленні страв',
  ADD_NEW: 'Додавання закладу',
  BACK_BTN: 'Повернутися',
  DETAIL_INFO: 'Контактна інформація',
  EMAIL_PLACEHOLDER: 'Введіть контактний email',
  EMAIL_ERROR: 'Не вірний email',
  WEBSITE: 'Вебсайт',
  WEBSITE_PLACEHOLDER: 'Вкажіть сайт закладу',
  WEBSITE_ERROR: 'Недопустимий формат вебсайту',
  ADDRESS_PLACEHOLDER: 'Вкажіть адресу закладу',
  LATITUDE: 'Координати (широта)',
  LONGITUDE: 'Координати (довгота)',
  AGREE: 'Згоден з',
  CONDITIONS: ' умовами обробки й збереження інформації',
  BACK_PLACES: 'До списку закладів',
  ADD_PLACE_TITLE: 'Заклад додано!',
  ADD_PLACE_SUBTITLE: 'Вкажіть менеджерів, яким дозволено працювати з новим закладом ',
  SHOW_MAP: 'Показати на мапі',
  HIDE_MAP: 'Сховати мапу',
  SELECT_ALL: 'Вибрати всіх',
};
