export default {
  PLACES: 'Network place',
  ADD: 'Add place',
  TITLE: 'Title',
  ADDRESS: 'Address',
  TEL: 'Telephone',
  ADD_TITLE: 'Add network place',
  ADD_SUBTITLE:
    'After adding an institution, you can configure its menu, payment types and hall schemes, as well as connect applications that will be used when ordering dishes',
  ADD_NEW: 'Adding place',
  BACK_BTN: 'Back',
  DETAIL_INFO: 'Contact Information',
  EMAIL_PLACEHOLDER: 'Enter your contact email',
  EMAIL_ERROR: 'Invalid email',
  WEBSITE: 'Website',
  WEBSITE_PLACEHOLDER: 'Specify the website',
  WEBSITE_ERROR: 'Incorrect website format',
  ADDRESS_PLACEHOLDER: 'Indicate the address',
  LATITUDE: 'Coordinates (latitude)',
  LONGITUDE: 'Coordinates (longitude)',
  AGREE: 'I agree to the',
  CONDITIONS: ' terms of information processing and storage',
  BACK_PLACES: 'To the list of places',
  ADD_PLACE_TITLE: 'Place added!',
  ADD_PLACE_SUBTITLE: 'Specify the managers who are allowed to work with the new place ',
  SHOW_MAP: 'Show on the map',
  HIDE_MAP: 'Hide map',
  SELECT_ALL: 'Select all',
};
