import React, {FC} from 'react';
import {Outlet} from 'react-router-dom';
import styled from 'styled-components';
import BaseImage from 'components/BaseComponents/BaseImage';
import Logo from 'assets/images/logo.webp';
import LogoText from 'assets/images/logo-text.webp';
import Background from 'assets/images/background.webp';

const PageLayout: FC = () => (
  <Wrapper>
    <HeaderLogo src={LogoText} />
    <HeaderBg />
    <LeftBlock>
      <LogoImage src={Logo} />
    </LeftBlock>
    <RightBlock>
      <Outlet />
    </RightBlock>
  </Wrapper>
);

const Wrapper = styled.div`
  display: flex;
  min-height: 100vh;
  padding: 7rem 0 3rem;
  box-sizing: border-box;
  color: ${({theme}) => theme.colors.gray100};

  @media ${({theme}) => theme.breakpoints.minLtg} {
    padding: 0;
  }
`;

const HeaderLogo = styled(BaseImage)`
  position: fixed;
  z-index: 3;
  top: 1.5rem;
  left: 1rem;
  height: 3.2rem;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: none;
  }
`;

const HeaderBg = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  height: 4.5rem;
  width: 100%;
  background-color: ${({theme}) => theme.colors.gray000};

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: none;
  }
`;

const RightBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 28rem;
  margin: 0 auto;

  @media ${({theme}) => theme.breakpoints.maxLtg} {
    width: 100%;
    max-width: 100%;
  }

  @media ${({theme}) => theme.breakpoints.minLtg} {
    position: relative;
    width: 50%;
    max-width: initial;
    margin: 0;
  }
`;

const LeftBlock = styled(RightBlock)`
  display: none;

  @media ${({theme}) => theme.breakpoints.minLtg} {
    display: flex;
    background: url(${Background}) center center / cover no-repeat;
  }
`;

const LogoImage = styled(BaseImage)`
  height: 17rem;
`;

export default PageLayout;
