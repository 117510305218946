import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from 'services/reduxStore';

export interface UserState {
  selectedPlaceId: string;
  selectedPlaceName: string;
}

const initialState: UserState = {
  selectedPlaceId: localStorage.getItem('placeId') || '',
  selectedPlaceName: localStorage.getItem('placeName') || '',
};

export const placesSlice = createSlice({
  name: 'places',
  initialState,
  reducers: {
    setPlaceId: (state, action: PayloadAction<string>) => {
      state.selectedPlaceId = action.payload;
      localStorage.setItem('placeId', action.payload);
    },
    setPlaceName: (state, action: PayloadAction<string>) => {
      state.selectedPlaceName = action.payload;
      localStorage.setItem('placeName', action.payload);
    },
  },
});

export const selectPlacesConfiguration = (state: RootState) => state.places;

export const {setPlaceId, setPlaceName} = placesSlice.actions;

export default placesSlice.reducer;
