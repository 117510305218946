import React, {lazy} from 'react';
import AuthPageLayout from './AuthPageLayout';
import PageLayout from './PageLayout';
import PlacePageLayout from './PlacePageLayout';
import MenuPageLayout from './MenuPageLayout';

// --------- Authorization routers --------- //
const Authorization = lazy(() => import('pages/Authorization/AuthorizationView'));
const Registration = lazy(() => import('pages/Registration/RegistrationView'));
const ResetPassword = lazy(() => import('pages/ResetPassword/ResetPasswordView'));

// --------- General tab routers --------- //
const Places = lazy(() => import('pages/Places/PlacesView'));
const Menus = lazy(() => import('pages/Menus/MenusView'));
const Organizations = lazy(() => import('pages/Organizations/OrganizationsView'));
const Managers = lazy(() => import('pages/Managers/ManagersView'));
const Profile = lazy(() => import('pages/Profile/ProfileView'));

// --------- Places details routers --------- //
const PlaceMain = lazy(() => import('pages/PlaceMain/PlaceMainView'));
const PlaceSettings = lazy(() => import('pages/PlaceSettings/PlaceSettingsView'));
const PlaceConfiguration = lazy(() => import('pages/PlaceConfiguration/PlaceConfigurationView'));
const PlaceDepartment = lazy(() => import('pages/PlaceDepartment/PlaceDepartmentView'));
const PlaceHalls = lazy(() => import('pages/PlaceHalls/PlaceHallsView'));
const PlacePersonnel = lazy(() => import('pages/PlacePersonnel/PlacePersonnelView'));
const PlacePaymentType = lazy(() => import('pages/PlacePaymentType/PlacePaymentTypeView'));
const PlaceApps = lazy(() => import('pages/PlaceApps/PlaceAppsView'));
const PlaceEquipment = lazy(() => import('pages/PlaceEquipment/PlaceEquipmentView'));
const PlaceMenu = lazy(() => import('pages/PlaceMenu/PlaceMenuView'));
const PlaceReports = lazy(() => import('pages/PlaceReports/PlaceReportsView'));
const MenuSettings = lazy(() => import('pages/MenuSettings/MenuSettingsView'));
const MenuPositions = lazy(() => import('pages/MenuPositions/MenuPositionsView'));
const MenuModifiers = lazy(() => import('pages/MenuModifiers/MenuModifiersView'));

// --------- Public routers --------- //
const Menu = lazy(() => import('pages/Menu/MenuView'));

const authRouters = [
  {
    path: '/auth',
    element: <Authorization />,
  },
  {
    path: '/auth/register',
    element: <Registration />,
  },
  {
    path: '/auth/resetPassword',
    element: <ResetPassword />,
  },
];

const mainRouters = [
  {
    path: '/places',
    element: <Places />,
  },
  {
    path: '/organizations',
    element: <Organizations />,
  },
  {
    path: '/menus',
    element: <Menus />,
  },
  {
    path: '/managers',
    element: <Managers />,
  },
  {
    path: '/profile',
    element: <Profile />,
  },
];

const menuRouters = [
  {
    path: '/menu-settings',
    element: <MenuSettings />,
  },
  {
    path: '/menu-positions',
    element: <MenuPositions />,
  },
  {
    path: '/menu-modifiers',
    element: <MenuModifiers />,
  },
];

const placeRouters = [
  {
    path: '/place-main',
    element: <PlaceMain />,
  },
  {
    path: '/place-settings',
    element: <PlaceSettings />,
  },
  {
    path: '/place-configuration',
    element: <PlaceConfiguration />,
  },
  {
    path: '/place-department',
    element: <PlaceDepartment />,
  },
  {
    path: '/place-halls',
    element: <PlaceHalls />,
  },
  {
    path: '/place-personnel',
    element: <PlacePersonnel />,
  },
  {
    path: '/place-payment',
    element: <PlacePaymentType />,
  },
  {
    path: '/place-apps',
    element: <PlaceApps />,
  },
  {
    path: '/place-equipment',
    element: <PlaceEquipment />,
  },
  {
    path: '/place-menu',
    element: <PlaceMenu />,
  },
  {
    path: '/place-reports',
    element: <PlaceReports />,
  },
];

const publicRouters = [
  {
    path: '/menu/:placeId',
    element: <Menu />,
  },
];

export const routersArr = [
  {
    key: 'authRouters',
    layout: <AuthPageLayout />,
    routers: authRouters,
  },
  {
    key: 'mainRouters',
    layout: <PageLayout />,
    routers: mainRouters,
  },
  {
    key: 'placeRouters',
    layout: <PlacePageLayout />,
    routers: placeRouters,
  },
  {
    key: 'menuRouters',
    layout: <MenuPageLayout />,
    routers: menuRouters,
  },
  {
    key: 'publicRouters',
    routers: publicRouters,
  },
];
