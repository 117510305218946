import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import userReducer from 'store/userSlice';
import placesReducer from 'store/placesSlice';
import menusReducer from 'store/menusSlice';
import notificationReducer from 'store/notificationSlice';

export const reduxStore = configureStore({
  reducer: {
    user: userReducer,
    places: placesReducer,
    menus: menusReducer,
    notification: notificationReducer,
  },
});

export type AppDispatch = typeof reduxStore.dispatch;
export type RootState = ReturnType<typeof reduxStore.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
