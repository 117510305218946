import React, {FC, ReactNode, useCallback, useState, useMemo, MouseEvent} from 'react';
import styled from 'styled-components';
import BaseLoader from './BaseLoader';
import Button from '@mui/material/Button';
import {useAppSelector} from 'services/hooks';
import {selectUserConfiguration} from 'store/userSlice';
import {globalLocalization} from 'services/GlobalUtils';

interface Props {
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
  disableRipple?: boolean;
  disabled?: boolean;
  variant?: 'contained' | 'outlined' | 'text';
  type?: 'submit' | 'reset' | 'button';
  children?: ReactNode | string;
  href?: string;
}

const BaseButton: FC<Props> = ({
  children,
  variant = 'contained',
  disableRipple,
  disabled,
  href,
  onClick,
  type,
  ...other
}) => {
  const {language} = useAppSelector(selectUserConfiguration);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (onClick && !isDisabled) {
        setIsDisabled(true);
        try {
          await onClick(e);
        } catch (error) {
          console.error('onClick execution error:', error);
        } finally {
          setIsDisabled(false);
        }
      }
    },
    [isDisabled, onClick]
  );

  const {SAVE} = globalLocalization(language);

  const isSaveBtn = useMemo(() => children === SAVE, [SAVE, children]);

  return (
    <Wrapper
      href={href}
      onClick={handleClick}
      variant={variant}
      disableRipple={disableRipple}
      disabled={disabled || (isSaveBtn && isDisabled)}
      type={type}
      {...other}
    >
      {isSaveBtn && isDisabled && <Loader />}
      {children}
    </Wrapper>
  );
};

const Wrapper = styled(Button)`
  &.MuiButton-root {
    text-transform: none;
    box-shadow: none;
  }
`;

const Loader = styled(BaseLoader)`
  width: 0.875rem;
  height: 0.875rem;
  border-color: ${({theme}) => theme.colors.gray030};
  margin: 0.3rem;
`;

export default BaseButton;
