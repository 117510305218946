import React, {StrictMode} from 'react';
import {createRoot} from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App';

// Sentry's React SDK automatic reporting of errors and exceptions
const dsn = process.env.REACT_APP_SENTRY_DSN_LINK;
dsn &&
Sentry.init({
  dsn,
  tracesSampleRate: 1.0,
  profilesSampleRate: 1.0,
  release: '0.1.3',
});

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);

root.render(
  <StrictMode>
    <App />
  </StrictMode>
);
